<template>
    <div>
        <div class="tile is-ancestor">
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Name') }}: {{ variable.name }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Dependent') }}: {{ variable.dependent }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space" v-if="variable.variable">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Variable') }}: {{ variable.variable.name }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space" v-if="variable.frequency">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Frequency') }}: {{ i18n(variable.frequency) }}
                    </p>
                </article>
            </div>
            <div class="tile is-parent space" v-if="variable.frequency">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Type') }}: {{ i18n(variable.type) }}
                    </p>
                </article>
            </div>
          <div class="tile is-parent space" v-if="variable.role">
                <article class="tile is-child box section-style titles">
                    <p>
                        {{ i18n('Users role') }}: {{ i18n(variable.role.name) }}
                    </p>
                </article>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Show',
    inject: ['route', 'http', 'i18n'],
    data() {
        return {
            variable: {},
        };
    },

    mounted() {
        this.fetchData();
    },

    methods: {
        fetchData() {
            this.http
                .get(this.route('variable.show', { variable: this.$route.params.variable }))
                .then(({ data }) => {
                    this.variable = data.variable;
                })
                .catch(this.errorHandler);
        },
    },
};
</script>

<style lang="scss" scoped>
.medal {
    width: 10rem;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
}

@media (max-width: 748px) {
    .medal {
        width: 5.5rem;
    }
}
</style>
